import React from 'react';
import styled from 'styled-components';
import { useCloudinary } from '../../../../hooks/Cloudinary/useCloudinary';
import useSelector from '../../../../redux/typedHooks';

type NafLogoProps = {
  height?: string;
  width?: string;
};

const NafLogo = ({ height = '24px', width = '24px' }: NafLogoProps) => {
  const cld = useCloudinary();
  const logo = useSelector((state) => state.globalSettings.settings?.logo);

  const logoImage =
    (logo?.publicId && cld.image(logo.publicId).quality('auto:best').format('svg').toURL()) || undefined;

  return <StyledLogo src={logoImage} height={height} width={width} alt="NAF Logo" />;
};

const StyledLogo = styled.img<NafLogoProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export { NafLogo };
